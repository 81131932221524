<div [formGroup]="paymentForm">
  <div class="tw-mb-4 tw-text-lg" *ngIf="showOptions && showMethods">
    <bit-radio-group formControlName="method">
      <bit-radio-button id="method-card" [value]="paymentMethodType.Card">
        <bit-label>
          <i class="bwi bwi-fw bwi-credit-card" aria-hidden="true"></i>
          {{ "creditCard" | i18n }}</bit-label
        >
      </bit-radio-button>
      <bit-radio-button id="method-bank" [value]="paymentMethodType.BankAccount" *ngIf="!hideBank">
        <bit-label>
          <i class="bwi bwi-fw bwi-bank" aria-hidden="true"></i>
          {{ "bankAccount" | i18n }}</bit-label
        >
      </bit-radio-button>
      <bit-radio-button id="method-paypal" [value]="paymentMethodType.PayPal" *ngIf="!hidePaypal">
        <bit-label> <i class="bwi bwi-fw bwi-paypal" aria-hidden="true"></i> PayPal</bit-label>
      </bit-radio-button>
      <bit-radio-button id="method-credit" [value]="paymentMethodType.Credit" *ngIf="!hideCredit">
        <bit-label>
          <i class="bwi bwi-fw bwi-dollar" aria-hidden="true"></i>
          {{ "accountCredit" | i18n }}</bit-label
        >
      </bit-radio-button>
    </bit-radio-group>
  </div>
  <ng-container *ngIf="showMethods && method === paymentMethodType.Card">
    <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-mb-4">
      <div [ngClass]="trialFlow ? 'tw-col-span-12' : 'tw-col-span-4'">
        <app-payment-label-v2 for="stripe-card-number-element">{{
          "number" | i18n
        }}</app-payment-label-v2>
        <div id="stripe-card-number-element" class="form-control stripe-form-control"></div>
      </div>
      <div *ngIf="!trialFlow" class="tw-col-span-8 tw-flex tw-items-end">
        <img
          src="../../images/cards.png"
          alt="Visa, MasterCard, Discover, AmEx, JCB, Diners Club, UnionPay"
          width="323"
          height="32"
        />
      </div>
      <div [ngClass]="trialFlow ? 'tw-col-span-6' : 'tw-col-span-4'">
        <app-payment-label-v2 for="stripe-card-expiry-element">{{
          "expiration" | i18n
        }}</app-payment-label-v2>
        <div id="stripe-card-expiry-element" class="form-control stripe-form-control"></div>
      </div>
      <div [ngClass]="trialFlow ? 'tw-col-span-6' : 'tw-col-span-4'">
        <app-payment-label-v2 for="stripe-card-cvc-element">
          {{ "securityCodeSlashCVV" | i18n }}
          <a
            href="https://www.cvvnumber.com/cvv.html"
            tabindex="-1"
            target="_blank"
            rel="noreferrer"
            class="hover:tw-no-underline"
            appA11yTitle="{{ 'learnMore' | i18n }}"
          >
            <i class="bwi bwi-question-circle" aria-hidden="true"></i>
          </a>
        </app-payment-label-v2>
        <div id="stripe-card-cvc-element" class="form-control stripe-form-control"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showMethods && method === paymentMethodType.BankAccount">
    <bit-callout type="warning" title="{{ 'verifyBankAccount' | i18n }}">
      {{ "verifyBankAccountInitialDesc" | i18n }} {{ "verifyBankAccountFailureWarning" | i18n }}
    </bit-callout>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4" formGroupName="bank">
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "routingNumber" | i18n }}</bit-label>
        <input bitInput type="text" formControlName="routing_number" required appInputVerbatim />
      </bit-form-field>
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "accountNumber" | i18n }}</bit-label>
        <input bitInput type="text" formControlName="account_number" required appInputVerbatim />
      </bit-form-field>
      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "accountHolderName" | i18n }}</bit-label>
        <input
          bitInput
          type="text"
          formControlName="account_holder_name"
          required
          appInputVerbatim
        />
      </bit-form-field>

      <bit-form-field class="tw-col-span-6">
        <bit-label>{{ "bankAccountType" | i18n }}</bit-label>
        <bit-select formControlName="account_holder_type" required>
          <bit-option value="" label="-- {{ 'select' | i18n }} --"></bit-option>
          <bit-option value="company" label="{{ 'bankAccountTypeCompany' | i18n }}"></bit-option>
          <bit-option
            value="individual"
            label="{{ 'bankAccountTypeIndividual' | i18n }}"
          ></bit-option>
        </bit-select>
      </bit-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="showMethods && method === paymentMethodType.PayPal">
    <div class="tw-mb-3">
      <div id="bt-dropin-container" class="tw-mb-1"></div>
      <small class="tw-text-muted">{{ "paypalClickSubmit" | i18n }}</small>
    </div>
  </ng-container>
  <ng-container *ngIf="showMethods && method === paymentMethodType.Credit">
    <bit-callout>
      {{ "makeSureEnoughCredit" | i18n }}
    </bit-callout>
  </ng-container>
</div>
